<template>
  <div class="mb-3 main">
    <div class="dashboard-card new-ticket-card">
      <div class="d-flex">
        <h4 class="mb-1"> {{ $t('Open Tikcet')}}</h4>
      </div>

      <!-- Form: Personal Info Form -->
      <validation-observer ref="ticketRules">
        <b-form class="mt-1">
          <b-row>
            <!-- Field: title -->
            <b-col cols="12" md="12" lg="12">
              <b-form-group :label="$t('Title')" label-for="title">
                <validation-provider #default="{ errors }" rules="required" name="Title">
                  <b-form-input id="title" v-model="ticketData.title" :state="errors.length > 0 ? false:null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="6">
              <b-form-group :label="$t('Select Service')">
                <div class="lab-dropdown">
                  <div class="lab-dropdown-btn" @click="isShowDropdown = !isShowDropdown" ref="dropdownService">
                    <span class="lab-dropdown-btn-value">{{ ticketTypeLabel }}</span>
                    <span class="lab-dropdown-btn-icon">
                      <img :src="'https://api.illustrativedna.com/assets/icons/'+$store.state.appConfig.layout.skin+'/dropdown.svg'">
                    </span>
                  </div>
                  <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdown }">
                    <ul>
                      <li v-for="ticketType in ticketTypeOptions" 
                        :key="ticketType.value" 
                        @click="ticketData.ticketType =ticketType.value">
                        {{ ticketType.label }}
                      </li>
                    </ul>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <!-- Field: Message -->
            <b-col cols="12" md="12" lg="12">
              <quill-editor
                v-model="ticketData.message"
                :options="snowOption"
              />
            </b-col>
            <b-col cols="12" md="12" lg="12">
              <div class="mt-2">
                <div class="file-upload btn-file-upload" v-if="ticketData.file === null"
                    @click="openUpload()">
                  <feather-icon
                    icon="UploadIcon"
                    size="20"
                    class="text-primary"
                  />
                  Upload File
                </div>
                <div class="file-upload" v-else>
                  <feather-icon 
                    icon="Trash2Icon" size="18"
                    @click="removeUploadFile()"
                    class="text-danger btn-file-change-delete"/>
                  {{ this.ticketData.file.name }}
                  <feather-icon
                    icon="CheckIcon"
                    size="20"
                    class="text-success"
                  />
                </div>
              </div>
            </b-col>
          </b-row>

          <!-- Header: Personal Info -->
          <!-- <div class="d-flex mt-2">
            <feather-icon icon="UserIcon" size="19" class="text-primary" />
            <h4 class="mb-0 ml-50 text-primary">{{ $t('Sender Information')}}</h4>
          </div> -->

          <!-- Form: User Info Form -->
          <!-- <b-row class="mt-1">
            <b-col cols="12" md="6" lg="6">
              <b-form-group :label="$t('Full Name')" label-for="nameSureName">
                <validation-provider #default="{ errors }" rules="required" name="Full Name">
                  <b-form-input id="nameSureName" v-model="ticketData.nameSureName" :state="errors.length > 0 ? false:null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="6">
              <b-form-group :label="$t('Email Address')" label-for="email">
                <validation-provider #default="{ errors }" rules="required|email" name="Email Address">
                  <b-input-group>
                    <b-form-input id="email" v-model="ticketData.email" :state="errors.length > 0 ? false:null" />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row> -->

          <b-row class="mt-2">
            <b-col>
              <div class="d-flex align-items-center justify-content-end">
                <button class="lab-btn lab-btn-info btn-save" 
                  type="submit" @click.prevent="createTicket">
                   <b-spinner
                    v-if="$store.getters.getLoader"
                    class="mr-1"
                    small
                    variant="light"
                  />
                  Send
                </button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
import StaticOptions from '@/common/options/StaticOptions';

import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, min, max,
} from '@validations'

// Alert fade
import { heightFade } from '@core/directives/animations'

// Alert
import AlertService from '@/common/alert.service'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  components: {
    quillEditor,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    'height-fade': heightFade,
  },

  data() {
    return {
      snowOption: {
        theme: 'snow',
        modules: {
          toolbar: [['bold', 'italic', 'underline', 'strike'], ['link']],
        },
      },
      required,
      min,
      max,
      email,
      isPresent: false,
      // Cleave options
      options: {    
        phone: {
          blocks: [0, 2, 3, 0, 3, 4],
          delimiter: ' ',
          delimiters: ['+', '(', ')', ' ', '-', '-'],    
          numericOnly: true,
          uppercase: false,
          lowercase: false,
        },
      },

      isShowDropdown: false,
      ticketTypeOptions: StaticOptions.ticketTypeOptions,

      // User
      ticketData: {
        title: null,
        message: null,
        nameSureName: null,
        email: null,
        file: null,
        ticketType: 0,
      },
    }
  },

  methods: {
    fetchUserInfo() {
      this.$store.dispatch('profileSettings/fetchGeneralSettings')
        .then(response => {
           if (response.statusCode === 200) {     
            this.ticketData.nameSureName = `${response.result.firstName} ${response.result.lastName}`;
            this.ticketData.email = response.result.email;
          }
        })
    },

    createTicket() {
      this.$refs.ticketRules.validate().then(success => {
        if (success) {
          const formData = new FormData();
          formData.set('title', this.ticketData.title);
          formData.set('message', this.ticketData.message);
          formData.set('nameSureName', this.ticketData.nameSureName);
          formData.set('email', this.ticketData.email);
          formData.set('ticketType', this.ticketData.ticketType);
          formData.set('file', this.ticketData.file);

          this.$store.dispatch('ticket/createTicket', formData)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                });
                this.$router.push({ name: 'ticket-detail', params: { id: response.result } });
              } else {
                AlertService.error(this, response.message)
              }
            });
        }
      })
    },

    openUpload() {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';

      fileInput.addEventListener('change', event => {
        /* eslint-disable*/
        const file = event.target.files[0];
        const fileTypes = StaticOptions.ticketFileTypeOptions;
        if (fileTypes.indexOf(file.type) > -1) {
          this.ticketData.file = file;
        } else {
          AlertService.warning(this, 'Invalid file...! Accepted File Formats:(.jpg,.jpeg,.png)');
        }
      })
      fileInput.click();
    },
    removeUploadFile() {
      this.ticketData.file = null;
    },

    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownService;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdown = false;
        }
      } catch (error) {}
    },
  },
  computed: {
    ticketTypeLabel() {
      const findType = this.ticketTypeOptions.find(x => x.value === this.ticketData.ticketType);
      if (findType) {
        return findType.label;
      }

      return 'SELECT SERVICE';
    },
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },

  created() {
    this.fetchUserInfo();
  },
};
</script>
<style lang="scss" scoped>
.file-upload {
  font-size: 15px;
  padding: 0.5rem;
}
.btn-file-upload {
  width: 130px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #00adb53b;
  transition: all 0.05s linear;
}

.btn-file-upload:hover {
  transform: rotate(0deg) scale(1.03, 1.03);
}

.btn-file-change-delete {
  cursor: pointer;
  border-radius: 5px;
}

.btn-file-change-delete:hover {
  background-color: #ea54554f !important;
}

.main {
  margin: 0 auto;
  max-width: 1000px !important;
}

.new-ticket-card {
  padding: 1.5rem;
  .btn-save {
    padding: 0.5rem 1.2rem !important;
    font-size: 1rem;
  }
}

.dark-layout label {
  color: var(--lab-white) !important;
}
</style>
